<template>
  <button
    @click="download"
    class="btn btn-danger"
    :disabled="isDownloading">
    <fa icon="download"></fa>
    {{ btnTxt }}
    <span v-if="progress > 0">{{ progress }}%</span>
  </button>
</template>

<script>
import ExcelJS from 'exceljs'
import { LIST_DOC } from './graph'


export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    accountTrialBalanceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      sheetname: '000000',
      columns: [
        {label: 'เอกสาร', value: 'docCode'},
        {label: 'วันที่', value: 'closedAt'},
        {label: 'เดบิต Dr.', value: 'debit'},
        {label: 'เครดิต Cr.', value: 'credit'},
        {label: 'คงเหลือ', value: 'balance'},
        {label: 'รายละเอียด', value: 'description'},
      ],
      btnTxt: 'Full Report',
      isDownloading: false,
      totalAccount: 0,
      currentAccount: 0
    }
  },
  computed: {
    filename () {
      return `รายงานบัญชีแยกประเภท__${this.startDate}_${this.endDate}`
    },
    progress () {
      if (!this.isDownloading) return 0

      return this.$form.round(this.currentAccount * 100 / this.totalAccount, 0)
    }
  },
  methods: {
    async fetchRowsByAccountId (accountId) {
      return this.$apollo.query({
        query: LIST_DOC(this.templateType),
        variables: {
          accountTrialBalanceType: this.accountTrialBalanceType,
          accountId,
          startDate: this.startDate,
          endDate: this.endDate
        },
        fetchPolicy: 'no-cache'
      }).then(res => res.data.data)
    },
    async download () {
      if (this.isDownloading) return

      try {
        const arr = this.items.sort((a,b) => a.code.localeCompare(b.code))

        this.isDownloading = true
        this.btnTxt = 'Processing...'
        this.totalAccount = arr.length

        const workbook = new ExcelJS.Workbook()
        const sheet = workbook.addWorksheet('My Sheet')

        const colA = sheet.getColumn('A')
        colA.alignment = { horizontal: 'center' }
        colA.width = 15

        const colB = sheet.getColumn('B')
        colB.alignment = { horizontal: 'left' }
        colB.width = 22

        const colC = sheet.getColumn('C')
        colC.alignment = { horizontal: 'left' }
        colC.width = 50

        const moneyCols = ['D', 'E', 'F']
        moneyCols.forEach(colTxt => {
          const col = sheet.getColumn(colTxt)
          col.alignment = { horizontal: 'right' }
          col.width = 17
        })

        let curRow = sheet.rowCount + 1

        for (let index = 0; index < arr.length; index++) {
          const acc = arr[index];

          this.currentAccount = index + 1
          
          const {broughtForward, docs, carryForward} = await this.fetchRowsByAccountId(acc.id)

          if (!broughtForward && !carryForward && docs.length === 0) continue

          const totalDebit = docs.filter(v => v.amount > 0).reduce((t,v) => t += +v.amount, 0)
          const totalCredit = docs.filter(v => v.amount < 0).reduce((t, v) => t += +v.amount, 0)
          const totalAmount = totalDebit + totalCredit

          const carryForwardInvt = carryForward * -1

          const broughtForwardDebit = broughtForward > 0 ? broughtForward : 0
          const broughtForwardCredit = broughtForward < 0 ? broughtForward : 0

          const carryForwardDebit = carryForwardInvt > 0 ? carryForwardInvt : 0
          const carryForwardCredit = carryForwardInvt < 0 ? carryForwardInvt : 0

          const sumDebit = broughtForwardDebit + totalDebit + carryForwardDebit
          const sumCredit = broughtForwardCredit + totalCredit + carryForwardCredit

          sheet.addTable({
            name: 'MyTable',
            ref: `A${curRow.toString()}`,
            headerRow: true,
            columns: [
              {name: 'วันที่', filterButton: false},
              {name: 'เอกสาร', filterButton: false},
              {name: 'รายการ', filterButton: false},
              {name: 'จำนวนเดบิต', filterButton: false},
              {name: 'จำนวนเครดิต', filterButton: false},
              {name: 'ยอดคงเหลือ', filterButton: false},
            ],
            rows: [
              [
                '', 
                `เลขที่บัญชี ${acc.code}`, 
                acc.name,
                '',
                '',
                '',
              ],
              [
                this.$date.format(this.startDate).displayThai, 
                '',
                'ยอดยกมา',
                broughtForward > 0 ? this.$form.addComma(broughtForward) : '',
                broughtForward < 0 ? this.$form.addComma(broughtForward * -1) : '',
                broughtForward < 0 ? `${this.$form.addComma(broughtForward * -1)}CR` : this.$form.addComma(broughtForward),
              ],
              ...docs.map(doc => [
                this.$date.format(doc.closedAt).displayThai,
                doc.code,
                doc.name,
                doc.amount > 0 ? this.$form.addComma(doc.amount) : '',
                doc.amount < 0 ? this.$form.addComma(doc.amount * -1) : '',
                doc.balance < 0 ? `${this.$form.addComma(doc.balance * -1)}CR` : this.$form.addComma(doc.balance),
              ]),
              [
                this.$date.format(this.endDate).displayThai, 
                '',
                'ยอดยกไป',
                carryForwardInvt > 0 ? this.$form.addComma(carryForwardInvt) : '',
                carryForwardInvt < 0 ? this.$form.addComma(carryForwardInvt * -1) : '',
                carryForwardInvt < 0 ? `${this.$form.addComma(carryForwardInvt * -1)}CR` : this.$form.addComma(carryForwardInvt),
              ],
              ['','','','','',''],
              [
                '', 
                '',
                'ยอดประจำงวด',
                this.$form.addComma(totalDebit),
                this.$form.addComma(totalCredit * -1),
                totalAmount < 0 ? `${this.$form.addComma(totalAmount * -1)}CR` : this.$form.addComma(totalAmount),
              ],
              [
                '', 
                '',
                'ยอดรวม',
                this.$form.addComma(sumDebit),
                this.$form.addComma(sumCredit * -1),
                '',
              ],
            ]
          })

          curRow = sheet.rowCount + 3
        }

        workbook.xlsx.writeBuffer().then(data => {
          let blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = this.filename + '.xlsx';
          anchor.click();
          window.URL.revokeObjectURL(url)
        })
      } finally {
        this.isDownloading = false
        this.btnTxt = 'Full Report'
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
