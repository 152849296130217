<template>
  <div class="">
    <div class="">
      <div class="form-row">
        <sgv-input-date
          label="เริ่มต้น"
          class="col-6"
          isNull
          v-model="startDate"
          :validations="[
            {text: 'required!', value: $v.startDate.$dirty && $v.startDate.$error}
          ]">
        </sgv-input-date>

        <sgv-input-date
          label="สิ้นสุด"
          class="col-6"
          isNull
          v-model="endDate"
          :validations="[
            {text: 'required!', value: $v.endDate.$dirty && $v.endDate.$error}
          ]">
        </sgv-input-date>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          label="ตั้งค่าเอกสาร"
          inline
          v-model="isDocConfig">
        </sgv-input-check>

        <sgv-input-check
          label="บันทึกเอกสาร"
          inline
          v-model="isLedger">
        </sgv-input-check>
      </div>
    </div>

    <div class="mb-3">
      <button
        type="button"
        class="btn btn-warning"
        @click="fetchData">
        ค้นหา
      </button>

      <ListTableFullReport
        class="float-right ml-2"
        v-if="accountsMapped.length > 0 && startDate && endDate"
        :items="accountsMapped"
        :startDate="startDate"
        :endDate="endDate"
        :accountTrialBalanceType="accountTrialBalanceType"
        :templateType="templateType">
      </ListTableFullReport>

      <ListTableExcel
        class="float-right"
        v-if="accountsMapped.length > 0 && startDate && endDate"
        :items="accountsMapped"
        :startDate="startDate"
        :endDate="endDate">
      </ListTableExcel>
    </div>

    <small>
      <sgv-table
        :rKey="rKey"
        :items="accountsMapped"
        :headers="headers"
        :filter.sync="filter"
        :options.sync="options"
        :rowsPerPage="rowsPerPage"
        :toolbars="toolbars"
        :tableClass="['table-hover', 'table-bordered']">

        <template slot-scope="{item, hidden}">
          <tr>
            <td v-if="hidden.code" class="no-wrap">
              <router-link 
                class="text-decoration-none" 
                :to="toDetail(item.id)">
                {{item.code}}
              </router-link>
            </td>
            <td v-if="hidden.name">
              <span v-if="item.level === 0" class="no-wrap">
                <router-link 
                  class="text-decoration-none" 
                  :to="toDetail(item.id)">
                  <u>{{item.name}}</u>
                </router-link>
              </span>
              <span v-else-if="!item.isDone" class="no-wrap">
                <span
                  v-for="n in item.level"
                  :key="n"
                  class="pl-3">
                </span>
                <router-link 
                  class="text-decoration-none" 
                  :to="toDetail(item.id)">
                  <u>{{item.name}}</u>
                </router-link>
              </span>
              <span v-else class="no-wrap">
                <span
                  v-for="n in item.level"
                  :key="n"
                  class="pl-3">
                </span>
                <router-link 
                  class="text-decoration-none" 
                  :to="toDetail(item.id)">
                  <span>- {{item.name}}</span>
                </router-link>
              </span>
              <div v-if="item.docConfigs.length > 0 && isDocConfig">
                <small class="text-info">
                  <span v-if="item.level > 0">
                    <span
                      v-for="n in item.level"
                      :key="n"
                      class="pl-3">
                    </span>
                  </span>
                  <span>ตั้งค่า: </span>
                  <router-link
                    v-for="(docType,idx) in item.docConfigs" :key="idx"
                    class="text-decoration-none mr-1 text-info"
                    :to="toDoc(docType)">
                    {{docType}}
                  </router-link>
                </small>
              </div>
              <div v-if="item.ledgers.length > 0 && isLedger">
                <small class="text-warning">
                  <span v-if="item.level > 0">
                    <span
                      v-for="n in item.level"
                      :key="n"
                      class="pl-3">
                    </span>
                  </span>
                  <span>บันทึก: </span>
                  <router-link
                    v-for="(docType,idx) in item.ledgers" :key="idx"
                    class="text-decoration-none mr-1 text-warning"
                    :to="toDoc(docType)">
                    {{docType}}
                  </router-link>
                </small>
              </div>
            </td>

            <td v-if="hidden.broughtForwardDebit" class="text-success no-wrap">
              <span v-if="item.broughtForwardAmount > 0">{{item.broughtForwardAmount | comma}}</span>
              <span v-else>-</span>

              <div v-if="!item.isDone && item.totalBroughtForwardAmount > 0" class="text-info">
                ({{item.totalBroughtForwardAmount | comma}})
              </div>
            </td>
            <td v-if="hidden.broughtForwardCredit" class="text-danger no-wrap">
              <span v-if="item.broughtForwardAmount < 0">{{item.broughtForwardAmount * -1 | comma}}</span>
              <span v-else>-</span>

              <div v-if="!item.isDone && item.totalBroughtForwardAmount < 0" class="text-info">
                ({{item.totalBroughtForwardAmount * -1 | comma}})
              </div>
            </td>

            <td v-if="hidden.betweenPeriodDebit" class="text-success no-wrap">
              <span v-if="item.betweenPeriodAmount > 0">{{item.betweenPeriodAmount | comma}}</span>
              <span v-else>-</span>

              <div v-if="!item.isDone && item.totalBetweenPeriodAmount > 0" class="text-info">
                ({{item.totalBetweenPeriodAmount | comma}})
              </div>
            </td>
            <td v-if="hidden.betweenPeriodCredit" class="text-danger no-wrap">
              <span v-if="item.betweenPeriodAmount < 0">{{item.betweenPeriodAmount * -1 | comma}}</span>
              <span v-else>-</span>

              <div v-if="!item.isDone && item.totalBetweenPeriodAmount < 0" class="text-info">
                ({{item.totalBetweenPeriodAmount * -1 | comma}})
              </div>
            </td>

            <td v-if="hidden.carryForwardDebit" class="text-success no-wrap">
              <span v-if="item.carryForwardAmount > 0">{{item.carryForwardAmount | comma}}</span>
              <span v-else>-</span>

              <div v-if="!item.isDone && item.totalCarryForwardAmount > 0" class="text-info">
                ({{item.totalCarryForwardAmount | comma}})
              </div>
            </td>
            <td v-if="hidden.carryForwardCredit" class="text-danger no-wrap">
              <span v-if="item.carryForwardAmount < 0">{{item.carryForwardAmount * -1 | comma}}</span>
              <span v-else>-</span>

              <div v-if="!item.isDone && item.totalCarryForwardAmount < 0" class="text-info">
                ({{item.totalCarryForwardAmount * -1 | comma}})
              </div>
            </td>
          </tr>
        </template>
      </sgv-table>
    </small>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import ListTableExcel from './ListTableExcel.vue'
import ListTableFullReport from './ListTableFullReport.vue'
import { LIST_TRIAL_BALANCE } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    accountTrialBalanceType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `AccountTrialBalance${this.$form.capitalize(this.accountTrialBalanceType)}ListTable`,
      detailView: `AccountTrialBalance${this.$form.capitalize(this.accountTrialBalanceType)}Detail`,
      rFields: ['filter', 'options', 'startDate', 'endDate', 'isDocConfig', 'isLedger'],
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'ชื่อ', value: 'name'},
        {text: 'Dr.ยกมา', value: 'broughtForwardDebit'},
        {text: 'Cr.ยกมา', value: 'broughtForwardCredit'},
        {text: 'Dr.ในงวด', value: 'betweenPeriodDebit'},
        {text: 'Cr.ในงวด', value: 'betweenPeriodCredit'},
        {text: 'Dr.ยกไป', value: 'carryForwardDebit'},
        {text: 'Cr.ยกไป', value: 'carryForwardCredit'},
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: [
          'code', 'name', 
          'broughtForwardDebit', 'broughtForwardCredit',
          'betweenPeriodDebit', 'betweenPeriodCredit',
          'carryForwardDebit', 'carryForwardCredit',
        ],
        column: null,
        search: null,
        toolbar: null,
      },
      rowsPerPage: [
        {text: 'ทั้งหมด', value: null}
      ],
      toolbars: [
        {value: 'filter', icon: 'cog', class: 'text-warning'}
      ],
      broughtForwardAccounts: [],
      betweenPeriodAccounts: [],
      startDate: '',
      endDate: '',
      isDocConfig: false,
      isLedger: false,
    }
  },
  validations: {
    startDate: { required },
    endDate: { required },
  },
  computed: {
    accountsMapped () {
      return this.betweenPeriodAccounts
      .filter(acc => !acc.parentId)
      .reduce((t, parent) => {
        const broughtForwardAmount = this.broughtForwardAccounts.find(v => v.id === parent.id)?.amount || 0
        const carryForwardAmount = +broughtForwardAmount + +parent.amount
        const children = this.getChildren(parent.id, 0, this.betweenPeriodAccounts.filter(x => x.parentId === parent.id))

        return [
          ...t,
          {
            id: parent.id,
            code: parent.code,
            name: parent.name,
            amount: parent.amount,
            level: 0,
            isDone: children.length === 0,
            docConfigs: parent.docConfigs,
            ledgers: parent.ledgers,

            broughtForwardAmount,
            totalBroughtForwardAmount: children.reduce((ct, child) => ct += +child.broughtForwardAmount, broughtForwardAmount),

            betweenPeriodAmount: parent.amount,
            totalBetweenPeriodAmount: children.reduce((ct, child) => ct += +child.amount, parent.amount),

            carryForwardAmount,
            totalCarryForwardAmount: children.reduce((ct, child) => ct += +child.carryForwardAmount, carryForwardAmount),
          },
          ...children
        ]
      }, [])
    }
  },
  methods: {
    getChildren (parentId, level, children) {
      if (children.length === 0) return []

      return this.betweenPeriodAccounts
      .filter(acc => acc.parentId === parentId)
      .reduce((t, child) => {
        const broughtForwardAmount = this.broughtForwardAccounts.find(v => v.id === child.id)?.amount || 0
        const carryForwardAmount = +broughtForwardAmount + +child.amount
        const arr = this.getChildren(child.id, level+1, this.betweenPeriodAccounts.filter(x => x.parentId === child.id))
        return [
          ...t,
          {
            id: child.id,
            code: child.code,
            name: child.name,
            amount: child.amount,
            level: level+1,
            isDone: arr.length === 0,
            docConfigs: child.docConfigs,
            ledgers: child.ledgers,

            broughtForwardAmount,
            totalBroughtForwardAmount: arr.reduce((ct, ch) => ct += +ch.broughtForwardAmount, broughtForwardAmount),

            betweenPeriodAmount: child.amount,
            totalBetweenPeriodAmount: arr.reduce((ct, ch) => ct += +ch.amount, child.amount),

            carryForwardAmount,
            totalCarryForwardAmount: arr.reduce((ct, ch) => ct += +ch.carryForwardAmount, carryForwardAmount),
          },
          ...arr
        ]
      }, [])
    },
    toDoc (docType) {
      return {
        name: `Doc${this.$form.capitalize(docType)}List`
      }
    },
    toDetail(id) {
      return {
        name: this.detailView,
        query: {
          accountId: id,
          startDate: this.startDate,
          endDate: this.endDate
        }
      }
    },
    fetchData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.setRetaining()

      this.broughtForwardAccounts = []
      this.betweenPeriodAccounts = []

      this.$apollo.query({
        query: LIST_TRIAL_BALANCE(this.templateType),
        variables: {
          accountTrialBalanceType: this.accountTrialBalanceType,
          startDate: this.startDate,
          endDate: this.endDate
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.broughtForwardAccounts = res.data.data.broughtForwardAccounts
        this.betweenPeriodAccounts = res.data.data.betweenPeriodAccounts
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  watch: {
    startDate: 'setRetaining',
    endDate: 'setRetaining',
    isDocConfig: 'setRetaining',
    isLedger: 'setRetaining',
    options: {
      handler: 'setRetaining',
      deep: true
    }
  },
  components: {
    ListTableExcel,
    ListTableFullReport
  }
}
</script>

<style lang="css" scoped>
strong {
  font-weight: bold;
}
</style>
